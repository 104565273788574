<template>
    <div class="seller-fee-content mb-5 mx-auto">
      <h5 class="seller-fee-header">SELLER SUCCESS FEE STRUCTURE</h5>
      <p class="seller-fee-desc">
        It is free to advertise on AutoFlip. The success fee is applicable only
        when your car is sold. Below is the seller success fee structure:
      </p>
      <ul class="seller-fee-table mt-4 fs-16">
        <li>
          <p class="bold">Sale value</p>
          <p>Success fee</p>
        </li>
        <li v-for="key in Object.keys(successFees)" :key="key">
          <p>{{ successFees[key] }}</p>
          <p>{{ key }}</p>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: "Index",
    data() {
      return {
        successFees: {
          $165: "Upto $4,999",
          $187: "$5,000 to $9,999",
          $275: "$10,000 to $19,999",
          $297: "$20,000 to $29,999",
          $385: "$30,000 to $39,999",
          $440: "$40,000 to $49,999",
          $550: "$50,000 to $59,999",
          $638: "$60,000 to $69,999",
          $660: "$70,000 and above",
        },
      };
    },
  };
  </script>
  
  <style lang="scss">
  .seller-fee-content {
    color: #404040;
    max-width: 394px;
    padding: 35px 20px 63px;
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 20px;
  
    @media screen and (min-width: 768px) {
      max-width: 1080px;
      min-height: calc(100vh - 273px);
      padding: 45px 50px 63px;
    }
  
    .seller-fee-header {
      text-align: center !important;
    }
  
    .seller-fee-desc {
      font-size: 14px;
  
      @media screen and (min-width: 768px) {
        font-size: 16px !important;
      }
    }
  
    .seller-fee-table {
      max-width: 500px;
      padding-inline-start: 0;
  
      li {
        border-top: 1px solid #404040;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.5rem;
  
        p:nth-child(2) {
          font-family: Avenir Next LT W05 Demi;
        }
      }
    }
  
    h5 {
      margin-bottom: 12px;
      font-size: 18px;
  
      @media screen and (min-width: 768px) {
        font-size: 20px !important;
      }
    }
  
    p {
      font-family: Avenir Next LT W05 Thin;
      font-weight: 600;
      margin-bottom: 0;
      padding: 5px 0;
    }
  
    .bold {
      font-family: Avenir Next LT W05 Demi;
    }
  }
  </style>